import { Delete, Edit, Notifications } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import {
  Delete_Products,
  Enable_Disable_Products,
  Export_Excel_Products,
  Get_Details_Products,
  Put_Notification_Send,
} from "../../../../Apis/productsApi/ProductsApi";
import ProductsContext from "../../../../context/ProductsContext/ProductsContext";
import { useNavigate } from "react-router-dom";
import NotificationModal from "./NotificationModal";
import NotificationContext from "../../../../context/NotificationContext/NotificationContext";

const ProductsDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
    setSubCatIdData,
    selectAttribute,
    setSelectAttribute,
    setSaveAttData,
    setProductId,
    productId,
  } = useContext(ProductsContext);
  const {
    setNotificationDialogs,
    formData: notificationFormData,
    setFormData: setNotificationFormData,
  } = useContext(NotificationContext);
  const { isLoading, data, refetch } = props;
  const history = useNavigate();

  /**
   * get products Details
   */
  const { mutate: productDetails, isLoading: isLoadingUserDetails } =
    useMutation((productId) =>
      Get_Details_Products(
        productId,
        history,
        setFormData,
        setSubCatIdData,
        setSelectAttribute,
        setSaveAttData
      )
    );

  const GetProduct = (productId) => {
    productDetails(productId);
    setId(productId);
    setIsEdit(false);
  };

  /**
   * product notification
   */
  const { mutate: productNotification, isLoading: isLoadingNotification } =
    useMutation((formData) => Put_Notification_Send(formData), {
      onSuccess: (response) => {
        if (response.status === 200) {
          emptyField();
        }
      },
    });

  const emptyField = () => {
    setProductId("");
    setNotificationFormData({
      image: "",
      imageUrl: "",
      title: "",
      template: "",
    });
    setNotificationDialogs(false);
  };

  const addProductNotification = (productId) => {
    let formData = {
      productId,
      ...notificationFormData,
    };
    const { image, ...newData } = formData;
    productNotification(newData);
  };

  /**
   * delete products
   */
  const { mutate: productsDelete, isLoading: isLoadingDelete } = useMutation(
    (productId) => Delete_Products(productId, refetch)
  );

  const DeleteProduct = async (productId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      productsDelete(productId);
    }
  };

  /**
   * enable / disable products
   */

  const { mutate: statusChangeMutate } = useMutation(
    (formData) => Enable_Disable_Products(formData),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          ToastMessage(response.status, response.data.message);
        }
      },
    }
  );

  const handleProductsStatusChange = (productId, status) => {
    let formDate = {
      productId: productId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.productId === productId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * excel download products
   */

  const { mutate: downloadExcel } = useMutation((formData) =>
    Export_Excel_Products(formData)
  );

  const handleExport = () => {
    downloadExcel();
  };

  const handleOpenandClosePopup = () => {
    history("/products/add");
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 180,
      field: "image",
      headerName: "Image",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <input
          type="image"
          img="true"
          src={
            params?.row?.defaultImage
              ? params?.row?.defaultImage?.imagePath
              : require("../../../../assets/Images/ImageNotFound.png")
          }
          alt={params?.row?.defaultImage?.imgAltText}
          style={{ height: "2rem" }}
        />
      ),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "productName",
      headerName: "Product Name",
      renderCell: (params) =>
        params.row.productName ? params.row.productName : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "mrp",
      headerName: "MRP",
      renderCell: (params) => (params.row.mrp ? params.row.mrp : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "sellPrice",
      headerName: "Sell Price",
      renderCell: (params) =>
        params.row.sellPrice ? params.row.sellPrice : "--",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleProductsStatusChange(
                params.row.productId,
                params.row.isActive
              )
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 150,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() => GetProduct(params.row.productId)}
              >
                <Edit style={{ color: "#7366ff" }} />
              </IconButton>
            </Tooltip>
          </div>
          {/* <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Notification">
              <IconButton
                aria-label="edit"
                onClick={() => {
                  setProductId(params.row.productId);
                  setNotificationDialogs(true);
                }}
              >
                <Notifications style={{ color: "#F16647" }} />
              </IconButton>
            </Tooltip>
          </div> */}
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => DeleteProduct(params.row.productId)}
              >
                <Delete style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={
          isLoadingUserDetails || isLoadingDelete || isLoadingNotification
        }
      />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.productId}
        addButton={true}
        isExport={true}
        handleExport={handleExport}
      />
      <NotificationModal
        addNotification={addProductNotification}
        id={productId}
        emptyField={emptyField}
      />
    </Fragment>
  );
};

export default ProductsDataTabelContainer;
