import React, { Fragment, useContext } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation } from "react-query";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import {
  Submit,
  TYPE_FELDS,
  TITLE,
  SUB_TITLE,
  ORDER,
  FileType,
  ImageNotFound,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createFeatureSchema } from "../../../../utils/validationSchema";
import CropImagePopup from "../../../CropImagePopup";
import { DELETE_IMAGE, Upload_Image } from "../../../../Apis/userApi/UserApi";
import FeatureContext from "../../../../context/FeatureContext/FeatureContext";
import {
  Create_Feature,
  Put_Update_Feature,
} from "../../../../Apis/featureApi/FeatureApi";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";

const FeatureCreateFormPopup = (props) => {
  const {
    setFormData,
    id,
    isEdit,
    setdialogs,
    formData,
    errors,
    setErrors,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
    dialogModal,
    setdialogsModal,
  } = useContext(FeatureContext);
  const { refetch, emptyField } = props;

  /**
   *
   * @param {event } e
   * create feature & update feature
   */

  const { mutate: createFeature, isLoading: createFeatureLoader } = useMutation(
    (formData) => Create_Feature(formData),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setdialogs(false);
          emptyField();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    }
  );

  const { mutate: updateFeature, isLoading: updateFeatureLoader } = useMutation(
    (formData) => Put_Update_Feature(formData, id),
    {
      onSuccess: (response) => {
        refetch();
        setdialogs(false);
        emptyField();
        setErrors({});
        ToastMessage(response.status, response.data.message);
      },
    }
  );

  const handleFeatureCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createFeatureSchema();
      await schema.validate(formData, { abortEarly: false });
      if (id !== -1 && isEdit === false) {
        let newData = {
          ...formData,
          imageUrl: formData?.image
            ? formData?.image
                .split(`${process.env.REACT_APP_FILE_FOLDER}`)
                .pop()
            : null,
        };
        updateFeature(newData);
      } else {
        let newData = {
          ...formData,
          imageUrl: formData?.image
            ? formData?.image
                .split(`${process.env.REACT_APP_FILE_FOLDER}`)
                .pop()
            : null,
        };
        createFeature(newData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  const { mutate: uploadImage } = useMutation((file) =>
    Upload_Image(file, setdialogsModal, setFormData, FileType.FEATURES)
  );

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeFeature = (e) => {
    const { name, value } = e.target;
    if (name === "brandName") {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
          slugUrl: value.replace(/\s+/g, "-").toLowerCase(),
        };
      });
    } else if (name === "slugUrl") {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value.replace(/\s+/g, "-").toLowerCase(),
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const openImageUploadModal = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (_event) => {
      setFormData((prev) => {
        return {
          ...prev,
          image: reader.result,
        };
      });
    };
    setdialogsModal(true);
  };

  /**
   * delete feature image
   */
  const { mutate: postsDelete, isLoading: isLoadingDelete } = useMutation(
    (imgData) => DELETE_IMAGE(imgData)
  );

  const deleteImage = async (imgPath) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      let imgData = {
        fileName: imgPath.split(`${process.env.REACT_APP_FILE_FOLDER}`).pop(),
      };
      setFormData((prev) => {
        return {
          ...prev,
          image: "",
        };
      });
      postsDelete(imgData);
    }
  };

  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={createFeatureLoader || updateFeatureLoader}
      />
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form
                className="needs-validation"
                onSubmit={(e) => handleFeatureCreateAndUpdate(e)}
              >
                <Row>
                  <div className="field mb-4 col-12 md:col-12">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          height: "150px",
                          width: "185px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={
                            formData?.image ? formData?.image : ImageNotFound
                          }
                          alt=""
                          style={{
                            width: "80%",
                            height: "98%",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {!formData?.image && (
                        <input
                          className="custom-file-input"
                          type="file"
                          onChange={(e) => openImageUploadModal(e)}
                        />
                      )}

                      <Button
                        className="p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
                        style={{ height: "40px" }}
                        type="button"
                        onClick={() => deleteImage(formData.image)}
                      >
                        <span
                          className={`p-button-icon p-c p-button-icon-left pi pi-camera `}
                        ></span>
                        <span
                          className="p-button-label p-c"
                          style={{ fontWeight: "100" }}
                        >
                          {"Choose"}
                        </span>
                      </Button>
                      {errors.image && (
                        <span className="error-validation">{errors.image}</span>
                      )}
                    </div>
                  </div>
                  <Col md="4 mb-4">
                    <FormDataFeild
                      label={`${TITLE} *`}
                      name="title"
                      id="title"
                      type={TYPE_FELDS.text}
                      placeholder={`${TITLE} *`}
                      value={formData.title}
                      handleChange={handleChangeFeature}
                    />
                    {errors.title && (
                      <span className="error-validation">{errors.title}</span>
                    )}
                  </Col>
                  <Col md="4 mb-4">
                    <FormDataFeild
                      label={SUB_TITLE}
                      name="subTitle"
                      id="subTitle"
                      type={TYPE_FELDS.text}
                      placeholder={SUB_TITLE}
                      value={formData.subTitle}
                      handleChange={handleChangeFeature}
                    />
                  </Col>
                  <Col md="4 mb-4">
                    <FormDataFeild
                      label={`${ORDER} *`}
                      name="order"
                      id="order"
                      type={TYPE_FELDS.number}
                      placeholder={`${ORDER} *`}
                      value={formData.order}
                      handleChange={handleChangeFeature}
                    />
                    {errors.order && (
                      <span className="error-validation">{errors.order}</span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="12 mb-4" className="btn-style">
                    <Button
                      label={Submit}
                      icon="pi pi-check"
                      loading={createFeatureLoader || updateFeatureLoader}
                      type={TYPE_FELDS.submit}
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CropImagePopup
        dialogModal={dialogModal}
        setdialogsModal={setdialogsModal}
        image={formData?.image}
        aspect={aspect}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        setFormData={setFormData}
        uploadImage={uploadImage}
      />
    </Fragment>
  );
};

export default FeatureCreateFormPopup;
