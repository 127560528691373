import axios from "axios";
import {
  CHECK_SLUG_PRODUCTS,
  CREATE_PRODUCTS,
  DELETE_PRODUCTS,
  EDIT_IMAGE,
  EDIT_PRODUCTS,
  EDIT_PRODUCTS_EXTRA,
  ENABLE_DISABLE_PRODUCTS,
  EXCEL_PRODUCTS,
  FILE,
  GET_IMAGE,
  GET_PRODUCTS,
  GET_PRODUCTS_DETAILS,
  GET_PRODUCTS_EXTRA,
  NOTIFICATION_PRODUCT,
} from "../../constants/ApiConstant";
import { ToastMessage, randomStr } from "../../utils/Helper";
import { REDIRECT_PATH } from "../../Routes";
import { get_Brand_Master } from "../brandMasterApi/BrandMasterApi";
import { get_Category_Master } from "../categoryApi/CategoryApi";
import { EXTRA_PRODUCT_TYPE, FileType, TEXT_TYPE } from "../../constants";
import { get_Product_Tag_Master } from "../productTagMasterApi/ProductTagMasterApi";

/**
 * get products list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Products = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_PRODUCTS}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add products api call
 * @param {form data} formData
 * @param {products list function} refetch
 * @param {modal close} setdialogs
 * @param {emptyData} emptyData
 * @returns
 */

export const Create_Products = (formData, history, emptyData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_PRODUCTS}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      history(REDIRECT_PATH.PRODUCTS);
      emptyData();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable products api call
 * @param {form data as isActive & productId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Products = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_PRODUCTS}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { productId: formData.productId },
      }
    )
    .then((message) => {
      return message;
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details products
 * @param {*} productId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Products = (
  productId,
  history,
  setFormData,
  setSubCatIdData,
  setSelectAttribute = null,
  setSaveAttData = null,
  setAttributeImages = null
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_PRODUCTS_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { productId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      let paramObj = { showAll: true };
      let paramObjImage = { showAll: true, productId };
      let responseBradDrop = await get_Brand_Master(paramObj);
      let responseCategoryDrop = await get_Category_Master(paramObj);
      let responseProductImageList = await get_Products_Image(paramObjImage);
      let responseProductExtraList = await get_Products_Extra(paramObjImage);
      let finedValue = {},
        subCat = {},
        subToSub = {};
      if (data?.categoryDetail?.parentCategory?.parentCategory?.catId) {
        finedValue = responseCategoryDrop?.payload?.data?.find(
          (elem) =>
            elem.catId ===
            data?.categoryDetail?.parentCategory?.parentCategory?.catId
        );
        subCat = finedValue.subCategories;
        subToSub = finedValue.subCategories.find(
          (elem) => elem.catId === data?.categoryDetail?.parentCategory?.catId
        );
        setSubCatIdData({
          subCat: subCat,
          subToSub: subToSub,
        });
      } else if (data?.categoryDetail?.parentCategory?.catId) {
        finedValue = responseCategoryDrop?.payload?.data?.find(
          (elem) => elem.catId === data?.categoryDetail?.parentCategory?.catId
        );
        if (finedValue && "subCategories" in finedValue) {
          subCat = finedValue.subCategories;
          subToSub = finedValue.subCategories.find(
            (elem) => elem.catId === data?.categoryDetail?.parentCategory?.catId
          );
        }
        setSubCatIdData({
          subCat: subCat,
          subToSub: subToSub,
        });
      } else {
        finedValue = responseCategoryDrop?.payload?.data?.find(
          (elem) => elem.catId === data?.categoryDetail?.catId
        );
        if (finedValue && "subCategories" in finedValue) {
          subCat = finedValue.subCategories;
          subToSub = finedValue.subCategories.find(
            (elem) => elem.catId === data?.categoryDetail?.parentCategory?.catId
          );
        }
        setSubCatIdData({
          subCat: subCat,
          subToSub: subToSub,
        });
      }
      [responseBradDrop] = responseBradDrop?.payload?.data?.filter(
        (elemBrand) => elemBrand?.brandId === data?.brandId
      );
      let [typeOfExtraProduct] = EXTRA_PRODUCT_TYPE.filter(
        (elem) =>
          elem?.code === responseProductExtraList?.payload?.data[0]?.type
      );
      let responseProductTagList = await get_Product_Tag_Master(paramObj);
      let newTagsArray = data?.tags
        .map((elem) => {
          let tag = responseProductTagList?.payload?.data.find(
            (item) => item?.tagName === elem
          );
          if (tag) {
            return {
              code: tag?.tagName,
              name: tag?.tagName,
            };
          }
          return null;
        })
        .filter((item) => item !== null);

      if (data?.productAttributes?.length && setSaveAttData !== null) {
        setSaveAttData(true);

        let attProdSelectedMap = [];

        for (let i = 0; i < data?.productAttributes?.length; i++) {
          for (
            let j = 0;
            j < data?.productAttributes[i].attributePricingMaps.length;
            j++
          ) {
            attProdSelectedMap.push({
              attributeMasterId:
                data?.productAttributes[i].attributePricingMaps[j]
                  .attributeDetail.attributeMasterId,
              name: data?.productAttributes[i].attributePricingMaps[j]
                .attributeDetail.attributeMaster.name,
              attributeType:
                data?.productAttributes[i].attributePricingMaps[j]
                  .attributeDetail.attributeType,
            });
          }
        }
        const uniqueObjects = new Set();
        const removeDuplicateMaps = attProdSelectedMap.filter((obj) => {
          const key = JSON.stringify(obj);
          const isNew = !uniqueObjects.has(key);
          if (isNew) {
            uniqueObjects.add(key);
          }
          return isNew;
        });

        attProdSelectedMap = removeDuplicateMaps;

        if (setSelectAttribute !== null && setSelectAttribute) {
          setSelectAttribute(
            attProdSelectedMap.map((elem) => {
              return {
                categoryAtt: { ...elem },
                attValue: [],
              };
            })
          );
        }

        const defaultSelectedAttValue = (prev) => {
          let attValue = [...prev];
          for (let i = 0; i < data?.productAttributes?.length; i++) {
            for (
              let j = 0;
              j < data?.productAttributes[i].attributePricingMaps.length;
              j++
            ) {
              for (let k = 0; k < attProdSelectedMap.length; k++) {
                if (
                  attProdSelectedMap[k].attributeMasterId ===
                  data.productAttributes[i].attributePricingMaps[j]
                    .attributeDetail.attributeMasterId
                ) {
                  attValue[k].attValue.push({
                    attributeId:
                      data.productAttributes[i].attributePricingMaps[j]
                        ?.attributeId,
                    name: data.productAttributes[i].attributePricingMaps[j]
                      .attributeDetail.value,
                  });
                }
              }
            }
          }
          return attValue;
        };

        setSelectAttribute((prev) => {
          const response = defaultSelectedAttValue(prev);
          function removeDuplicates(arr) {
            const uniqueValues = [];
            const seenValues = new Set();

            arr.forEach((item) => {
              const key = `${item.attributeId}_${item.name}`;

              if (!seenValues.has(key)) {
                seenValues.add(key);
                uniqueValues.push(item);
              }
            });

            return uniqueValues;
          }
          response.forEach((obj) => {
            obj.attValue = removeDuplicates(obj.attValue);
          });
          return [...response];
        });
      }

      const attributeImages = {};

      setFormData({
        productName: data ? data?.productName : null,
        sku: data ? data?.sku : null,
        sortDesc: data ? data?.sortDesc : null,
        longDesc: data ? data?.longDesc : null,
        mrp: data ? data?.mrp : null,
        sellPrice: data ? data?.sellPrice : null,
        slugUrl: data ? data?.slugUrl : null,
        seoTitle: data ? data?.seoTitle : null,
        seoDesc: data ? data?.seoDesc : null,
        seoKeyword: data ? data?.seoKeyword : null,
        brandId: data
          ? { name: responseBradDrop.brandName, code: responseBradDrop.brandId }
          : null,
        isReturn: data ? data?.isReturn : null,
        tax: data ? data?.tax : null,
        tags: data ? newTagsArray : null,
        taxPer: data ? data?.taxPer : null,
        prodType: data ? data?.prodType : null,
        order: data ? data?.order : null,
        taxType: data?.taxType
          ? TEXT_TYPE.find((findVal) => findVal.code === data?.taxType)
          : "",
        images: responseProductImageList?.payload?.data.length
          ? responseProductImageList?.payload?.data
              .filter((elem) => !elem?.attributePricingMapId)
              .map((elem) => {
                return {
                  id: randomStr(2),
                  imagePath: elem?.imagePath,
                  imgAltText: elem?.imgAltText?.length
                    ? elem?.imgAltText
                    : data?.productName,
                  isDefault: elem?.isDefault,
                  order: responseProductImageList?.payload?.data.length + 1,
                  imageId: elem?.imageId,
                };
              })
          : [],
        extraProductIds: responseProductExtraList?.payload?.data.length
          ? responseProductExtraList?.payload?.data.map((elem) => {
              return {
                code: elem?.extraProductId,
                name: elem?.extraProductDetail?.productName,
              };
            })
          : null,

        type: responseProductExtraList?.payload?.data?.length
          ? typeOfExtraProduct
          : null,
        catId: data
          ? {
              code:
                data?.categoryDetail?.parentCategory?.parentCategory != null &&
                data?.categoryDetail?.parentCategory != null
                  ? data?.categoryDetail?.parentCategory?.parentCategory?.catId
                  : data?.categoryDetail?.catId,
              name:
                data?.categoryDetail?.parentCategory?.parentCategory != null &&
                data?.categoryDetail?.parentCategory != null
                  ? data?.categoryDetail?.parentCategory?.parentCategory?.name
                  : data?.categoryDetail?.name,
            }
          : null,
        subCat: data
          ? {
              code:
                data?.categoryDetail?.parentCategory != null
                  ? data?.categoryDetail?.parentCategory?.catId
                  : "",
              name:
                data?.categoryDetail?.parentCategory != null
                  ? data?.categoryDetail?.parentCategory?.name
                  : "",
            }
          : null,
        subToSubCat: data
          ? {
              code:
                data?.categoryDetail?.parentCategory?.parentCategory != null &&
                data?.categoryDetail?.parentCategory != null
                  ? data?.categoryDetail?.catId
                  : "",
              name:
                data?.categoryDetail?.parentCategory?.parentCategory != null &&
                data?.categoryDetail?.parentCategory != null
                  ? data?.categoryDetail?.name
                  : "",
            }
          : null,
        attributes: data?.productAttributes?.length
          ? data?.productAttributes?.map((elem, attributeIndex) => {
              return {
                id: randomStr(2),
                attributePricingId: elem?.attributePricingId,
                attributeId: elem?.attributePricingMaps?.map((elemAttMap) => {
                  if (!attributeImages[attributeIndex]) {
                    attributeImages[attributeIndex] = {};
                  }

                  if (
                    !Array.isArray(
                      attributeImages[attributeIndex][
                        elemAttMap?.attributeDetail?.attributeMasterId
                      ]
                    )
                  ) {
                    attributeImages[attributeIndex][
                      elemAttMap?.attributeDetail?.attributeMasterId
                    ] = [];
                  }

                  elemAttMap.productImages.forEach((image) => {
                    attributeImages[attributeIndex][
                      elemAttMap?.attributeDetail?.attributeMasterId
                    ].push({
                      id: randomStr(2),
                      ...image,
                    });
                  });
                  return {
                    code: elemAttMap.attributeId,
                    name: elemAttMap?.attributeDetail?.value,
                    attributeType: elemAttMap?.attributeDetail?.attributeType,
                    attributeMasterId:
                      elemAttMap?.attributeDetail?.attributeMasterId,
                  };
                }),
                attributeArr: elem?.attributePricingMaps?.map((elemAttMap) => {
                  return {
                    attributeId: elemAttMap.attributeId,
                    attributeMasterId:
                      elemAttMap?.attributeDetail?.attributeMasterId,
                    attributeType: elemAttMap?.attributeDetail?.attributeType,
                    value: elemAttMap?.attributeDetail?.value,
                  };
                }),
                attributeType:
                  elem?.attributePricingMaps[0].attributeDetail?.attributeType,
                mrp: elem?.mrp,
                sellPrice: elem?.sellPrice,
                taxAmt: elem?.taxAmt,
                taxType: elem?.taxType
                  ? TEXT_TYPE.find((findVal) => findVal.code === elem?.taxType)
                  : "",
                taxPer: elem?.taxPer || "",
                isDefault: true,
                images: elem?.productImages?.map((elemImage) => {
                  return {
                    id: randomStr(2),
                    imagePath: elemImage?.imagePath,
                    isDefault: false,
                    imageId: elemImage?.imageId,
                  };
                }),
              };
            })
          : [],
      });
      if (setAttributeImages && Object.keys(attributeImages).length) {
        setAttributeImages(attributeImages);
      }
      history(`/products/${productId}`);
      return res;
    });
};

/**
 * update products api call
 * @param {form data} formData
 * @param {*} productId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {emptyData} emptyData
 * @returns
 */

export const Put_Update_Products = (
  formData,
  productId,
  history,
  emptyData
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_PRODUCTS}`, formData, {
      headers: { Authorization: jwt_token },
      params: { productId },
    })
    .then((message) => {
      history(REDIRECT_PATH.PRODUCTS);
      emptyData();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

export const Put_Update_Extra_Products = (formData, productId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_PRODUCTS_EXTRA}`, formData, {
      headers: { Authorization: jwt_token },
      params: { productId },
    })
    .then((message) => {
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete products api call
 * @param {form data} formData
 * @param {*} brandId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Products = (productId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_PRODUCTS}`, {
      headers: { Authorization: jwt_token },
      params: { productId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * check slug products api call
 * @param {form data} formData
 * @param {*} productId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {form data func} setFormData
 * @returns
 */

export const Get_Check_Slug_Url = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${CHECK_SLUG_PRODUCTS}`, {
      headers: { Authorization: jwt_token },
      params: formData,
    })
    .then((message) => {})
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

export const Upload_Image = (
  file,
  setdialogsModal,
  setFormData,
  index, //main index
  isEdit,
  productId,
  imageFormData,
  indexAttributeImage, // image wise index
  isAttribute,
  setAttributeImages,
  attributeMasterId,
  attributeMasterIndex,
  setIsImageUpload
) => {
  const jwt_token = localStorage.getItem("authToken");
  let formData = new FormData();
  formData.append("type", FileType.PRODUCT);
  formData.append("file", file);
  setIsImageUpload(true);
  return axios
    .post(`${process.env.REACT_APP_API_URL}${FILE}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then(async (message) => {
      if (isEdit === false) {
        let old = [...imageFormData.images];
        if (!!old[index]) {
          old[index]["imagePath"] =
            message.data.payload.baseUrl + message.data.payload.imageUrl;
          old[index]["order"] = imageFormData.images.length + 1;
          old[index]["productId"] = productId;
        }
        if (old[index]?.imageId) {
          await Put_Update_Products_Image(old[index], productId);
        } else {
          setFormData((prev) => {
            return {
              ...prev,
              images: old,
              logo: "",
            };
          });
        }
      }
      if (isAttribute) {
        setAttributeImages((prev) => {
          let old = [...prev[attributeMasterIndex][attributeMasterId]];
          old[indexAttributeImage].imagePath =
            message.data.payload.baseUrl + message.data.payload.imageUrl;
          return {
            ...prev,
            [attributeMasterIndex]: { [attributeMasterId]: old },
          };
        });
      } else {
        setFormData((prev) => {
          let old = [...prev.images];
          old[index]["imagePath"] =
            message.data.payload.baseUrl + message.data.payload.imageUrl;
          old[index]["order"] = prev.images.length + 1;
          return {
            ...prev,
            images: old,
            logo: "",
          };
        });
      }
      ToastMessage(message.status, message.data.message);
      setIsImageUpload(false);
      setdialogsModal(false);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * get products image list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Products_Image = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_IMAGE}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * get products extra list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Products_Extra = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_PRODUCTS_EXTRA}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * update products image api call
 * @param {form data} formData
 * @param {*} productId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {form data func} setFormData
 * @returns
 */

export const Put_Update_Products_Image = (formData, productId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_IMAGE}`, formData, {
      headers: { Authorization: jwt_token },
      params: { imageId: formData.imageId },
    })
    .then((message) => {
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * export excel products api call
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Export_Excel_Products = (paramObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${EXCEL_PRODUCTS}`, {
      headers: { Authorization: jwt_token, Accept: "text/csv" },
      params: paramObj,
      responseType: "blob",
    })
    .then((response) => {
      const data = response.data;
      const downloadLink = document.createElement("a");
      const blobUrl = window.URL.createObjectURL(data);
      downloadLink.href = blobUrl;
      downloadLink.download = "products.csv";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(blobUrl);
      ToastMessage(response.status, response);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

export const Put_Notification_Send = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${NOTIFICATION_PRODUCT}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      ToastMessage(message.status, message.data.message);
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
