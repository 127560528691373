import { REDIRECT_PATH } from "../Routes";

export const TIME_ZONE = "+0530";

export const LOGIN_IN_DEMO_APP = "Login in Demo App";
export const NUMBER = "Number";
export const DIAL_CODE = "Dial Code";
export const PASSWORD = "Password";
export const LOGIN = "Log in";
export const NEW_PASSWORD = "New Password";
export const FORGOT = "Forgot Password";
export const SHIP_STATUS1 = [
  {
    Value: 1,
    lable: "Order Confirmed",
  },
  { Value: 2, lable: "Shipped" },
  { Value: 3, lable: "Out For Delivery" },
  { Value: 4, lable: "Delivered" },
];

export const TypeNumber = {
  PHONE: 10,
  GST: 15,
  PAN: 10,
};

export const STATUS_CODE = {
  INTERNAL_SERVER: 401,
  SUCCESS: 200,
};

export const Name = `Name *`;
export const TagName = `Tag Name *`;
export const TagColor = `Tag Color`;
export const DeliveryCharge = `Delivery Charge *`;
export const CompanyName = `Company Name`;
export const TaxLabel = `Tax Label *`;
export const MinOrderAmount = `Min Order Amount *`;
export const NumberOfImagesPerProduct = `Image Per Product *`;
export const currency = `Currency *`;
export const DefaultTimeZone = `Default Timezone *`;
export const DefaultDateFormat = `Default Date Format *`;
export const WhatsApp = `Whatsapp`;
export const TaxId = `TaxId`;
export const MinOrderQty = `Min Order Qty`;
export const AdminPercentage = `Admin Percentage`;
export const SkipLogin = `Skip Login`;
export const StockShow = `Stock Show`;
export const DefaultSortListing = `Default Short`;
export const AndroidKey = `Android Key`;
export const IosKey = `ios Key`;
export const InvoiceTerms = `Invoice Terms`;
export const OrderOnWhatsapp = `Order On Whatsapp`;
export const websiteTitle = `Website Title`;
export const seoTitle = `seo Title`;
export const seoDesc = `seo Desc`;
export const seoKeyword = `seo Keyword`;
export const googleTag = `Google Tag`;
export const facebookTag = `Facebook Tag`;
export const schema = `Schema`;
export const facebook = `Facebook`;
export const instagram = `Instagram`;
export const youtube = `Youtube`;
export const playStoreLink = `PlayStore Link`;
export const appStoreLink = `AppStore Link`;
export const serviceOrderAmt = `Service Order Amount`;
export const serviceLessThenCharge = `Service Less Then Charge`;
export const serviceGreaterThenCharge = `Service Greater Then Charge`;
export const codeOnOff = `COD On/Off`;
export const isOnlinePayment = `OnlinePayment On/Off`;
export const scheduleOnOff = `Schedule On/Off`;
export const nextSlot = `Next Slot`;
export const codCharge = `COD Charge`;
export const scheduleEnableDays = `Schedule Enable Days`;
export const scheduledDeliveryAssignBefore = `Scheduled Delivery Assign Before`;
export const User = `User *`;
export const BrandName = `Brand Name *`;
export const BrandDesc = `Brand Description *`;
export const SlugUrl = `Slug *`;
export const LATITUDE = `latitude *`;
export const LONGITUDE = `longitude *`;
export const Caption = `Caption`;
export const TYPE_ = `Type *`;
export const START_END_DATE = `Start Date - End Date`;
export const START_Time = `Start Time`;
export const END_Time = `End Time`;
export const SKU = `SKU`;
export const TAX = `TAX`;
export const TAX_PER = `TAX Percentage`;
export const SEO_Title = `SEO Title`;
export const TITLE = `Title`;
export const HYPER_LINK = `Hyper Link`;
export const SUB_TITLE = `Sub Title`;
export const PAGE_URL = `Page Url`;
export const DETAILS_MASTER = `Details Heading`;
export const ORDER = `Order`;
export const ATTRIBUTE_MASTER = `Attribute Master *`;
export const ATTRIBUTE_TYPE = `Attribute Type`;
export const SEO_Desc = `SEO Description`;
export const SEO_Keyword = `SEO Keyword`;
export const SortDescription = `Short Description`;
export const LongDescription = `Long Description`;
export const Description = `Description`;
export const Address = "Address *";
export const Phone = "Phone *";
export const ProductName = "Product Name *";
export const Alt = "Alt";
export const Product = "Product";
export const ADD_MINUS = "+ OR -";
export const Total_Qty = "Total QTY";
export const Store_ = "Store *";
export const SlotName = "Slot Name *";
export const AttributeName = "Attribute Name *";
export const Value = "Value *";
export const Slot = "Slot *";
export const SlotOfType = "Slot Of Type *";
export const OffDate = "Off Date *";
export const Brand = "Brand *";
export const pCatId = "Parent Category";
export const Category_Attribute_Map = "Category Attribute Map";
export const Attribute = "Attribute";
export const HomeOrder = "Home Order";
export const Category = "Category";
export const ExtraType = "Extra Product Type";
export const ExtraProduct = "Extra Product";
export const ProductSuggestion = "Product Suggestion";
export const TextType = "Tax Type";
export const Tag = "Tag";
export const MRP = "MRP *";
export const QTY = "QTY";
export const StoreName = "Store";
export const MIN_STOCK = "Min Stock";
export const MAX_STOCK = "Max Stock";
export const NOTE = "Note";
export const SellPrice = "Selling Price *";
export const Email = "Email *";
export const GSTNo = "GST";
export const City = "City";
export const PanNo = "Pan No.";
export const State = "State";
export const PasswordLabel = "Password *";
export const ConfirmPassword = "Confirm Password *";
export const TempleName = "Temple Name *";
export const TempleEmail = "Email";
export const Mobile = "Mobile";
export const PancardNo = "Pancard No.";
export const Country = "Country";
export const TempleAddress = "Address";
export const Submit = "Submit";
export const Addnew = "Add New";
export const AddBulk = "Add Bulk";
export const Close = "Close";
export const Create = "Create";
export const Update = "Update";
export const Fname = "First Name *";
export const Mname = "Middle Name";
export const Lname = "Last Name *";
export const Gender = "Gender";
export const DialCode = "DIalcode *";
export const Rider = "Rider *";
export const Contact_No = "Contact No. *";
export const Village = "Village *";
export const IdProofNumber = "Id Proof No.*";
export const Email_ = "Email";
export const Date_Of_Birth = "Date Of Birth";
export const Age = "Age";
export const Address_ = "Address";
export const Pin_Code = "Pincode";
export const Id_Proof = "Id Proof";
export const Image = "Image";
export const Ref_Guest = "Refrence Guest";
export const Assign_Guest_House = "Assign Guest House";
export const Assign_Admin = "Assign Admin";
export const Category_Name = "Category";
export const Rate = "Rate *";
export const Deposit = "Deposit";
export const Guest_House = "Guest House *";
export const Guest = "Guest *";
export const RoomCategory = "Room Category *";
export const Rooms = "Rooms *";
export const Capacity = "Capecity *";
export const ExtraBed = "Extra Bed *";
export const Facilities = "Facilities *";
export const Status = "Status *";
export const To = "To *";
export const From = "From *";
export const Member = "Member *";
export const checkInAndCheckOut = "Check In * & Check Out *";
export const TotalAmount = "Total Amount";
export const RefundAmount = "Refund Amount";
export const Roles = "Role *";
export const DeviceId = "Device Id";
export const VehicleNumber = "Vehicle Number";
export const VehicleType = "Vehicle Type";
export const PromoCode = `Promo Code *`;
export const DiscountType = `Discount Type`;
export const DiscountValue = `Discount Value *`;
export const ApplyType = `Apply Type`;
export const MaxDiscountAmount = `Max Discount Amount`;
export const MinOrderAmount_ = `Min Order Amount`;
export const DealOfTheDay = `Deal Of The Day *`;

export const OrganizationDetails = "Organization Details";
export const UserDetails = "User Details";
export const CreateTempleMaster = "Create Temple Master";
export const UpdateTempleMaster = "Update Temple Master";
export const CreateUser = "Create User";
export const CreateBrand = "Create Brand";
export const UpdateBrand = "Update Brand";
export const CreateFeature = "Create Feature";
export const UpdateFeature = "Update Feature";
export const CreateStore = "Create Store";
export const UpdateStore = "Update Store";
export const CreateDetailsPage = "Create Details Page";
export const UpdateDetailsPage = "Update Details Page";
export const CreateDetailsMasterPage = "Create Details Heading";
export const UpdateDetailsMasterPage = "Update Details Heading";
export const CreateOrderSetting_ = "Create Order Setting";
export const UpdateOrderSetting_ = "Update Order Setting";
export const CreateGuestHouseMaster = "Create Guest House Master";
export const GuestHouseMasterDetails = "Guest House Master Details";
export const GuestDetails = "Guest Details";
export const CreateRoom = "Create Room";
export const UpdateRoom = "Update Room";
export const RoomDetails = "Room Details";
export const CreateDeliverySlot = "Create Delivery Slot";
export const UpdateDeliverySlot = "Update Delivery Slot";
export const CreateDeliveryOff = "Create Delivery Off";
export const UpdateDeliveryOff = "Update Delivery Off";
export const CreatePincode = "Create Pincode";
export const UpdatePincode = "Update Pincode";
export const CreateDeliveryPincode = "Create Delivery Pincode";
export const UpdateDeliveryPincode = "Update Delivery Pincode";
export const CreateAttribute = "Create Attribute Master";
export const UpdateAttribute = "Update Attribute Master";
export const CreateAttribute_ = "Create Attribute";
export const UpdateAttribute_ = "Update Attribute";
export const Create_Category_Attribute_map = "Create Category Attribute Map";
export const Update_Category_Attribute_map = "Update Category Attribute Map";
export const CreateAssignOrderToRider = "Assign Order to Rider";
export const UpdateOrderStatus = "Update Order Status";
export const UpdateAssignOrderToRider = "Update Assign Order to Rider";
export const ORDER_BY = "Product Order no";
export const Ship_status = "Ship Status";
export const Order_status = "Order Status";
export const ImageNotFound =
  "https://bytebiz.fra1.digitaloceanspaces.com/dev-byte-ecomm/users/99401710925963252.jpg";

/**
 * Fields Types
 */

export const TYPE_FELDS = {
  text: "text",
  number: "number",
  password: "password",
  email: "email",
  submit: "submit",
  date: "date",
  button: "button",
};

export const TAG = [
  {
    code: 1,
    name: "Hot",
  },
  {
    code: 2,
    name: "New",
  },
  {
    code: 3,
    name: "Best Sell",
  },
  {
    code: 4,
    name: "Sale",
  },
];

/**
 * Tabel Coloums Array
 */

export const ORGANIZATION_TABEL = [
  {
    name: "Image",
  },
  {
    name: "Name",
  },
  {
    name: "Phone",
  },
  {
    name: "GST Number",
  },
  {
    name: "Address",
  },
  {
    name: "City",
  },
  {
    name: "State",
  },
  {
    name: "CreatedAt",
  },
];

/**
 * Roles
 */

export const Role = {
  SuperAdmin: 1,
  OrgAdmin: 2,
  Store_Admin: 11,
  Delivery_Boy: 12,
  Customer: 13,
};
export const RoleDrop = [
  { code: 1, name: "Super Admin" },
  { code: 11, name: "Store Admin" },
  { code: 12, name: "Delivery Boy" },
  { code: 13, name: "Customer" },
];

/**
 * image base url
 */

export const BASE_URL_IMG =
  "https://mcode.fra1.digitaloceanspaces.com/hisab-kitab/";

/**
 * dashboard items array
 */

export const DASHBOARD_ITEMS = [
  {
    id: 1,
    name: "Current Booking",
    icon: "pi-users",
    number: 20,
  },
  {
    id: 2,
    name: "Room Allcoated",
    icon: "pi-check-circle",
    number: 20,
  },
  {
    id: 4,
    name: "Available Guest",
    icon: "pi-circle-fill",
    number: 0,
  },
  {
    id: 5,
    name: "Today's Arrival",
    icon: "pi-sign-in",
    number: 0,
  },
  {
    id: 5,
    name: "Today's Check Out",
    icon: "pi-sign-out",
    number: 20,
  },
  {
    id: 5,
    name: "Rooms Available",
    icon: "pi-building",
    number: 20,
  },
  {
    id: 6,
    name: "Rooms Available",
    icon: "pi-building",
    number: "90 / 90",
  },
  {
    id: 7,
    name: "Beds Available",
    icon: "pi-building",
    number: "540 / 540",
  },
  {
    id: 9,
    name: "Under Maintenance",
    icon: "pi-filter-slash",
    number: 0,
  },
];

/**
 * image type
 */

export const IMAPE_TYPE = {
  ID_PROOF: 1,
  GUEST_IMAGE: 2,
};

/**
 * extra product type
 */

export const EXTRA_PRODUCT_TYPE = [
  { code: 1, name: "extra" },
  { code: 2, name: "reference" },
];

/**
 * banner slider type
 */

export const BANNER_SLIDER_TYPE = [
  { code: 1, name: "Slider" },
  { code: 2, name: "Banner" },
];

/**
 * image base uri
 */

export const ImgaeBaseUri =
  "https://mcode.fra1.digitaloceanspaces.com/guest-house/";

/**
 * gender drop
 */

export const GENDER = [
  {
    name: "MALE",
    code: 1,
  },
  {
    name: "FE-MALE",
    code: 2,
  },
];

/**
 * room master status
 */

export const ROOMS_ALLOCATION_STATUS = {
  Available: 1,
  Allocation: 2,
  Maintenance: 3,
  Reserve: 4,
  Event_Allocation: 5,
};

export const ROOM_STATUS = [
  {
    code: 1,
    name: "Available",
  },
  {
    code: 2,
    name: "Allocation",
  },
  {
    code: 3,
    name: "Maintenance",
  },
  {
    code: 4,
    name: "Reserve",
  },
  {
    code: 5,
    name: "Event Allocation",
  },
];

export const FileType = {
  USER: 1,
  PRODUCT: 2,
  CATEGORY: 3,
  BANNER: 4,
  SLIDER: 5,
  FEATURES: 6,
  NOTIFICATION: 7,
};

export const TYPE = [
  { code: "1", name: "(+) ADD" },
  { code: "2", name: "(-) Minus" },
];

export const Currency = [
  { name: "United Arab Emirates Dirham (د.إ)", code: "د.إ" },
  { name: "Afghan Afghani (Af)", code: "Af" },
  { name: "Albanian Lek (Lek)", code: "Lek" },
  { name: "Armenian Dram (դ)", code: "դ" },
  { name: "Netherlands Antillean Guilder (ƒ)", code: "ƒ" },
  { name: "Angolan Kwanza (Kz)", code: "Kz" },
  { name: "Argentine Peso ($)", code: "$" },
  { name: "Australian Dollar ($)", code: "$" },
  { name: "Aruban Florin (ƒ)", code: "ƒ" },
  { name: "Azerbaijani Manat (₼)", code: "₼" },
  { name: "Bosnia-Herzegovina Convertible Mark (KM)", code: "KM" },
  { name: "Barbadian Dollar ($)", code: "$" },
  { name: "Bangladeshi Taka (৳)", code: "৳" },
  { name: "Bulgarian Lev (лв)", code: "лв" },
  { name: "Bahraini Dinar (.د.ب)", code: ".د.ب" },
  { name: "Burundian Franc (FBu)", code: "FBu" },
  { name: "Bermudian Dollar ($)", code: "$" },
  { name: "Brunei Dollar ($)", code: "$" },
  { name: "Bolivian Boliviano ($b)", code: "$b" },
  { name: "Brazilian Real (R$)", code: "R$" },
  { name: "Bahamian Dollar ($)", code: "$" },
  { name: "Bhutanese Ngultrum (Nu.)", code: "Nu." },
  { name: "Botswana Pula (P)", code: "P" },
  { name: "Belarusian Ruble (p.)", code: "p." },
  { name: "Belize Dollar (BZ$)", code: "BZ$" },
  { name: "Canadian Dollar ($)", code: "$" },
  { name: "Congolese Franc (FC)", code: "FC" },
  { name: "Swiss Franc (CHF)", code: "CHF" },
  { name: "Chilean Unit of Account (UF) (UF)", code: "UF" },
  { name: "Chilean Peso ($)", code: "$" },
  { name: "Chinese Yuan (¥)", code: "¥" },
  { name: "Colombian Peso ($)", code: "$" },
  { name: "Costa Rican Colón (₡)", code: "₡" },
  { name: "Cuban Peso (⃌)", code: "⃌" },
  { name: "Cape Verdean Escudo ($)", code: "$" },
  { name: "Czech Koruna (Kč)", code: "Kč" },
  { name: "Djiboutian Franc (Fdj)", code: "Fdj" },
  { name: "Danish Krone (kr)", code: "kr" },
  { name: "Dominican Peso (RD$)", code: "RD$" },
  { name: "Algerian Dinar (دج)", code: "دج" },
  { name: "Egyptian Pound (E£)", code: "E£" },
  { name: "Ethiopian Birr (Br)", code: "Br" },
  { name: "Euro (€)", code: "€" },
  { name: "Fijian Dollar ($)", code: "$" },
  { name: "Falkland Islands Pound (£)", code: "£" },
  { name: "British Pound Sterling (£)", code: "£" },
  { name: "Georgian Lari (ლ)", code: "ლ" },
  { name: "Ghanaian Cedi (¢)", code: "¢" },
  { name: "Gibraltar Pound (£)", code: "£" },
  { name: "Gambian Dalasi (D)", code: "D" },
  { name: "Guinean Franc (FG)", code: "FG" },
  { name: "Guatemalan Quetzal (Q)", code: "Q" },
  { name: "Guyanaese Dollar ($)", code: "$" },
  { name: "Hong Kong Dollar ($)", code: "$" },
  { name: "Honduran Lempira (L)", code: "L" },
  { name: "Croatian Kuna (kn)", code: "kn" },
  { name: "Haitian Gourde (G)", code: "G" },
  { name: "Hungarian Forint (Ft)", code: "Ft" },
  { name: "Indonesian Rupiah (Rp)", code: "Rp" },
  { name: "Israeli New Shekel (₪)", code: "₪" },
  { name: "Indian Rupee (₹)", code: "₹" },
  { name: "Iraqi Dinar (ع.د)", code: "ع.د" },
  { name: "Iranian Rial (﷼)", code: "﷼" },
  { name: "Icelandic Króna (kr)", code: "kr" },
  { name: "Jersey Pound (£)", code: "£" },
  { name: "Jamaican Dollar (J$)", code: "J$" },
  { name: "Jordanian Dinar (JD)", code: "JD" },
  { name: "Japanese Yen (¥)", code: "¥" },
  { name: "Kenyan Shilling (KSh)", code: "KSh" },
  { name: "Kyrgystani Som (лв)", code: "лв" },
  { name: "Cambodian Riel (៛)", code: "៛" },
  { name: "Comorian Franc (CF)", code: "CF" },
  { name: "North Korean Won (₩)", code: "₩" },
  { name: "South Korean Won (₩)", code: "₩" },
  { name: "Kuwaiti Dinar (د.ك)", code: "د.ك" },
  { name: "Cayman Islands Dollar ($)", code: "$" },
  { name: "Kazakhstani Tenge (₸)", code: "₸" },
  { name: "Laotian Kip (₭)", code: "₭" },
  { name: "Lebanese Pound (£)", code: "£" },
  { name: "Sri Lankan Rupee (₨)", code: "₨" },
  { name: "Liberian Dollar ($)", code: "$" },
  { name: "Lesotho Loti (L)", code: "L" },
  { name: "Lithuanian Litas (Lt)", code: "Lt" },
  { name: "Latvian Lats (Ls)", code: "Ls" },
  { name: "Libyan Dinar (ل.د)", code: "ل.د" },
  { name: "Moroccan Dirham (د.م.)", code: "د.م." },
  { name: "Moldovan Leu (L)", code: "L" },
  { name: "Malagasy Ariary (Ar)", code: "Ar" },
  { name: "Macedonian Denar (ден)", code: "ден" },
  { name: "Myanma Kyat (K)", code: "K" },
  { name: "Mongolian Tugrik (₮)", code: "₮" },
  { name: "Macanese Pataca (MOP$)", code: "MOP$" },
  { name: "Mauritanian Ouguiya (UM)", code: "UM" },
  { name: "Mauritian Rupee (₨)", code: "₨" },
  { name: "Maldivian Rufiyaa (.ރ)", code: ".ރ" },
  { name: "Malawian Kwacha (MK)", code: "MK" },
  { name: "Mexican Peso ($)", code: "$" },
  { name: "Malaysian Ringgit (RM)", code: "RM" },
  { name: "Mozambican Metical (MT)", code: "MT" },
  { name: "Namibian Dollar ($)", code: "$" },
  { name: "Nigerian Naira (₦)", code: "₦" },
  { name: "Nicaraguan Córdoba (C$)", code: "C$" },
  { name: "Norwegian Krone (kr)", code: "kr" },
  { name: "Nepalese Rupee (₨)", code: "₨" },
  { name: "New Zealand Dollar ($)", code: "$" },
  { name: "Omani Rial (﷼)", code: "﷼" },
  { name: "Panamanian Balboa (B/.)", code: "B/." },
  { name: "Peruvian Sol (S/.)", code: "S/." },
  { name: "Papua New Guinean Kina (K)", code: "K" },
  { name: "Philippine Peso (₱)", code: "₱" },
  { name: "Pakistani Rupee (₨)", code: "₨" },
  { name: "Polish Złoty (zł)", code: "zł" },
  { name: "Paraguayan Guarani (Gs)", code: "Gs" },
  { name: "Qatari Rial (﷼)", code: "﷼" },
  { name: "Romanian Leu (lei)", code: "lei" },
  { name: "Serbian Dinar (Дин)", code: "Дин" },
  { name: "Russian Ruble (₽)", code: "₽" },
  { name: "Rwandan Franc (ر.س)", code: "ر.س" },
  { name: "Saudi Riyal (﷼)", code: "﷼" },
  { name: "Solomon Islands Dollar ($)", code: "$" },
  { name: "Seychellois Rupee (₨)", code: "₨" },
  { name: "Sudanese Pound (£)", code: "£" },
  { name: "Swedish Krona (kr)", code: "kr" },
  { name: "Singapore Dollar ($)", code: "$" },
  { name: "Saint Helena Pound (£)", code: "£" },
  { name: "Sierra Leonean Leone (Le)", code: "Le" },
  { name: "Somali Shilling (S)", code: "S" },
  { name: "Surinamese Dollar ($)", code: "$" },
  { name: "São Tomé and Príncipe Dobra (Db)", code: "Db" },
  { name: "Salvadoran Colón ($)", code: "$" },
  { name: "Syrian Pound (£)", code: "£" },
  { name: "Swazi Lilangeni (L)", code: "L" },
  { name: "Thai Baht (฿)", code: "฿" },
  { name: "Tajikistani Somoni (TJS)", code: "TJS" },
  { name: "Turkmenistani Manat (m)", code: "m" },
  { name: "Tunisian Dinar (د.ت)", code: "د.ت" },
  { name: "Tongan Paʻanga (T$)", code: "T$" },
  { name: "Turkish Lira (₤)", code: "₤" },
  { name: "Trinidad and Tobago Dollar ($)", code: "$" },
  { name: "New Taiwan Dollar (NT$)", code: "NT$" },
  { name: "Tanzanian Shilling (TSh)", code: "TSh" },
  { name: "Ukrainian Hryvnia (₴)", code: "₴" },
  { name: "Ugandan Shilling (USh)", code: "USh" },
  { name: "United States Dollar ($)", code: "$" },
  { name: "Uruguayan Peso ($U)", code: "$U" },
  { name: "Uzbekistani Som (лв)", code: "лв" },
  { name: "Venezuelan Bolívar (Bs)", code: "Bs" },
  { name: "Vietnamese Dong (₫)", code: "₫" },
  { name: "Vanuatu Vatu (VT)", code: "VT" },
  { name: "Samoan Tala (WS$)", code: "WS$" },
  { name: "Central African CFA Franc (FCFA)", code: "FCFA" },
  { name: "East Caribbean Dollar ($)", code: "$" },
  { name: "Special Drawing Rights (SDR)", code: "SDR" },
  { name: "West African CFA Franc (FCFA)", code: "FCFA" },
  { name: "CFP Franc (F)", code: "F" },
  { name: "Yemeni Rial (﷼)", code: "﷼" },
  { name: "South African Rand (R)", code: "R" },
  { name: "Zambian Kwacha (ZK)", code: "ZK" },
  { name: "Zimbabwean Dollar (ZWL)", code: "ZWL" },
];

export const SLOT_OFF_TIME = {
  ALL: 1,
  SPECIFIC: 2,
};
export const SLOT_OFF_TIME_DROP = [
  {
    name: "ALL",
    code: 1,
  },
  { name: "SPECIFIC", code: 2 },
];

export const optionsDialCode = [
  { code: "+93", name: "+93" },
  { code: "+355", name: "+355" },
  { code: "+213", name: "+213" },
  { code: "+1-684", name: "+1-684" },
  { code: "+376", name: "+376" },
  { code: "+244", name: "+244" },
  { code: "+1-264", name: "+1-264" },
  { code: "+672", name: "+672" },
  { code: "+1-268", name: "+1-268" },
  { code: "+54", name: "+54" },
  { code: "+374", name: "+374" },
  { code: "+297", name: "+297" },
  { code: "+61", name: "+61" },
  { code: "+43", name: "+43" },
  { code: "+994", name: "+994" },
  { code: "+1-242", name: "+1-242" },
  { code: "+973", name: "+973" },
  { code: "+880", name: "+880" },
  { code: "+1-246", name: "+1-246" },
  { code: "+375", name: "+375" },
  { code: "+32", name: "+32" },
  { code: "+501", name: "+501" },
  { code: "+229", name: "+229" },
  { code: "+1-441", name: "+1-441" },
  { code: "+975", name: "+975" },
  { code: "+591", name: "+591" },
  { code: "+387", name: "+387" },
  { code: "+267", name: "+267" },
  { code: "+55", name: "+55" },
  { code: "+246", name: "+246" },
  { code: "+1-284", name: "+1-284" },
  { code: "+673", name: "+673" },
  { code: "+359", name: "+359" },
  { code: "+226", name: "+226" },
  { code: "+257", name: "+257" },
  { code: "+855", name: "+855" },
  { code: "+237", name: "+237" },
  { code: "+1", name: "+1" },
  { code: "+238", name: "+238" },
  { code: "+1-345", name: "+1-345" },
  { code: "+236", name: "+236" },
  { code: "+235", name: "+235" },
  { code: "+56", name: "+56" },
  { code: "+86", name: "+86" },
  { code: "+61", name: "+61" },
  { code: "+61", name: "+61" },
  { code: "+57", name: "+57" },
  { code: "+269", name: "+269" },
  { code: "+682", name: "+682" },
  { code: "+506", name: "+506" },
  { code: "+385", name: "+385" },
  { code: "+53", name: "+53" },
  { code: "+599", name: "+599" },
  { code: "+357", name: "+357" },
  { code: "+420", name: "+420" },
  { code: "+243", name: "+243" },
  { code: "+45", name: "+45" },
  { code: "+253", name: "+253" },
  { code: "+1-767", name: "+1-767" },
  { code: "+1-809, 1-829, 1-849", name: "+1-809, 1-829, 1-849" },
  { code: "+670", name: "+670" },
  { code: "+593", name: "+593" },
  { code: "+20", name: "+20" },
  { code: "+503", name: "+503" },
  { code: "+240", name: "+240" },
  { code: "+291", name: "+291" },
  { code: "+372", name: "+372" },
  { code: "+251", name: "+251" },
  { code: "+500", name: "+500" },
  { code: "+298", name: "+298" },
  { code: "+679", name: "+679" },
  { code: "+358", name: "+358" },
  { code: "+33", name: "+33" },
  { code: "+689", name: "+689" },
  { code: "+241", name: "+241" },
  { code: "+220", name: "+220" },
  { code: "+995", name: "+995" },
  { code: "+49", name: "+49" },
  { code: "+233", name: "+233" },
  { code: "+350", name: "+350" },
  { code: "+30", name: "+30" },
  { code: "+299", name: "+299" },
  { code: "+1-473", name: "+1-473" },
  { code: "+1-671", name: "+1-671" },
  { code: "+502", name: "+502" },
  { code: "+44-1481", name: "+44-1481" },
  { code: "+224", name: "+224" },
  { code: "+245", name: "+245" },
  { code: "+592", name: "+592" },
  { code: "+509", name: "+509" },
  { code: "+504", name: "+504" },
  { code: "+852", name: "+852" },
  { code: "+36", name: "+36" },
  { code: "+354", name: "+354" },
  { code: "+91", name: "+91" },
  { code: "+62", name: "+62" },
  { code: "+98", name: "+98" },
  { code: "+964", name: "+964" },
  { code: "+353", name: "+353" },
  { code: "+44-1624", name: "+44-1624" },
  { code: "+972", name: "+972" },
  { code: "+39", name: "+39" },
  { code: "+225", name: "+225" },
  { code: "+1-876", name: "+1-876" },
  { code: "+81", name: "+81" },
  { code: "+44-1534", name: "+44-1534" },
  { code: "+962", name: "+962" },
  { code: "+7", name: "+7" },
  { code: "+254", name: "+254" },
  { code: "+686", name: "+686" },
  { code: "+383", name: "+383" },
  { code: "+965", name: "+965" },
  { code: "+996", name: "+996" },
  { code: "+856", name: "+856" },
  { code: "+371", name: "+371" },
  { code: "+961", name: "+961" },
  { code: "+266", name: "+266" },
  { code: "+231", name: "+231" },
  { code: "+218", name: "+218" },
  { code: "+423", name: "+423" },
  { code: "+370", name: "+370" },
  { code: "+352", name: "+352" },
  { code: "+853", name: "+853" },
  { code: "+389", name: "+389" },
  { code: "+261", name: "+261" },
  { code: "+265", name: "+265" },
  { code: "+60", name: "+60" },
  { code: "+960", name: "+960" },
  { code: "+223", name: "+223" },
  { code: "+356", name: "+356" },
  { code: "+692", name: "+692" },
  { code: "+222", name: "+222" },
  { code: "+230", name: "+230" },
  { code: "+262", name: "+262" },
  { code: "+52", name: "+52" },
  { code: "+691", name: "+691" },
  { code: "+373", name: "+373" },
  { code: "+377", name: "+377" },
  { code: "+976", name: "+976" },
  { code: "+382", name: "+382" },
  { code: "+1-664", name: "+1-664" },
  { code: "+212", name: "+212" },
  { code: "+258", name: "+258" },
  { code: "+95", name: "+95" },
  { code: "+264", name: "+264" },
  { code: "+674", name: "+674" },
  { code: "+977", name: "+977" },
  { code: "+31", name: "+31" },
  { code: "+599", name: "+599" },
  { code: "+687", name: "+687" },
  { code: "+64", name: "+64" },
  { code: "+505", name: "+505" },
  { code: "+227", name: "+227" },
  { code: "+234", name: "+234" },
  { code: "+683", name: "+683" },
  { code: "+850", name: "+850" },
  { code: "+1-670", name: "+1-670" },
  { code: "+47", name: "+47" },
  { code: "+968", name: "+968" },
  { code: "+92", name: "+92" },
  { code: "+680", name: "+680" },
  { code: "+970", name: "+970" },
  { code: "+507", name: "+507" },
  { code: "+675", name: "+675" },
  { code: "+595", name: "+595" },
  { code: "+51", name: "+51" },
  { code: "+63", name: "+63" },
  { code: "+64", name: "+64" },
  { code: "+48", name: "+48" },
  { code: "+351", name: "+351" },
  { code: "+1-787, 1-939", name: "+1-787, 1-939" },
  { code: "+974", name: "+974" },
  { code: "+242", name: "+242" },
  { code: "+262", name: "+262" },
  { code: "+40", name: "+40" },
  { code: "+7", name: "+7" },
  { code: "+250", name: "+250" },
  { code: "+590", name: "+590" },
  { code: "+290", name: "+290" },
  { code: "+1-869", name: "+1-869" },
  { code: "+1-758", name: "+1-758" },
  { code: "+590", name: "+590" },
  { code: "+508", name: "+508" },
  { code: "+1-784", name: "+1-784" },
  { code: "+685", name: "+685" },
  { code: "+378", name: "+378" },
  { code: "+239", name: "+239" },
  { code: "+966", name: "+966" },
  { code: "+221", name: "+221" },
  { code: "+381", name: "+381" },
  { code: "+248", name: "+248" },
  { code: "+232", name: "+232" },
  { code: "+65", name: "+65" },
  { code: "+1-721", name: "+1-721" },
  { code: "+421", name: "+421" },
  { code: "+386", name: "+386" },
  { code: "+677", name: "+677" },
  { code: "+252", name: "+252" },
  { code: "+27", name: "+27" },
  { code: "+82", name: "+82" },
  { code: "+211", name: "+211" },
  { code: "+34", name: "+34" },
  { code: "+94", name: "+94" },
  { code: "+249", name: "+249" },
  { code: "+597", name: "+597" },
  { code: "+47", name: "+47" },
  { code: "+268", name: "+268" },
  { code: "+46", name: "+46" },
  { code: "+41", name: "+41" },
  { code: "+963", name: "+963" },
  { code: "+886", name: "+886" },
  { code: "+992", name: "+992" },
  { code: "+255", name: "+255" },
  { code: "+66", name: "+66" },
  { code: "+228", name: "+228" },
  { code: "+690", name: "+690" },
  { code: "+676", name: "+676" },
  { code: "+1-868", name: "+1-868" },
  { code: "+216", name: "+216" },
  { code: "+90", name: "+90" },
  { code: "+993", name: "+993" },
  { code: "+1-649", name: "+1-649" },
  { code: "+688", name: "+688" },
  { code: "+1-340", name: "+1-340" },
  { code: "+256", name: "+256" },
  { code: "+380", name: "+380" },
  { code: "+971", name: "+971" },
  { code: "+44", name: "+44" },
  { code: "+1", name: "+1" },
  { code: "+598", name: "+598" },
  { code: "+998", name: "+998" },
  { code: "+678", name: "+678" },
  { code: "+379", name: "+379" },
  { code: "+58", name: "+58" },
  { code: "+84", name: "+84" },
  { code: "+681", name: "+681" },
  { code: "+212", name: "+212" },
  { code: "+967", name: "+967" },
  { code: "+260", name: "+260" },
  { code: "+263", name: "+263" },
];

export const TImeZoneArr = [
  {
    code: "GMT-12:00",
    name: "Etc/GMT-12",
  },
  {
    code: "GMT-11:00",
    name: "Etc/GMT-11",
  },
  {
    code: "GMT-11:00",
    name: "Pacific/Midway",
  },
  {
    code: "GMT-10:00",
    name: "America/Adak",
  },
  {
    code: "GMT-09:00",
    name: "America/Anchorage",
  },
  {
    code: "GMT-09:00",
    name: "Pacific/Gambier",
  },
  {
    code: "GMT-08:00",
    name: "America/Dawson_Creek",
  },
  {
    code: "GMT-08:00",
    name: "America/Ensenada",
  },
  {
    code: "GMT-08:00",
    name: "America/Los_Angeles",
  },
  {
    code: "GMT-07:00",
    name: "America/Chihuahua",
  },
  {
    code: "GMT-07:00",
    name: "America/Denver",
  },
  {
    code: "GMT-06:00",
    name: "America/Belize",
  },
  {
    code: "GMT-06:00",
    name: "America/Cancun",
  },
  {
    code: "GMT-06:00",
    name: "America/Chicago",
  },
  {
    code: "GMT-06:00",
    name: "Chile/EasterIsland",
  },
  {
    code: "GMT-05:00",
    name: "America/Bogota",
  },
  {
    code: "GMT-05:00",
    name: "America/Havana",
  },
  {
    code: "GMT-05:00",
    name: "America/New_York",
  },
  {
    code: "GMT-04:30",
    name: "America/Caracas",
  },
  {
    code: "GMT-04:00",
    name: "America/Campo_Grande",
  },
  {
    code: "GMT-04:00",
    name: "America/Glace_Bay",
  },
  {
    code: "GMT-04:00",
    name: "America/Goose_Bay",
  },
  {
    code: "GMT-04:00",
    name: "America/Santiago",
  },
  {
    code: "GMT-04:00",
    name: "America/La_Paz",
  },
  {
    code: "GMT-03:00",
    name: "America/Argentina/Buenos_Aires",
  },
  {
    code: "GMT-03:00",
    name: "America/Montevideo",
  },
  {
    code: "GMT-03:00",
    name: "America/Araguaina",
  },
  {
    code: "GMT-03:00",
    name: "America/Godthab",
  },
  {
    code: "GMT-03:00",
    name: "America/Miquelon",
  },
  {
    code: "GMT-03:00",
    name: "America/Sao_Paulo",
  },
  {
    code: "GMT-03:30",
    name: "America/St_Johns",
  },
  {
    code: "GMT-02:00",
    name: "America/Noronha",
  },
  {
    code: "GMT-01:00",
    name: "Atlantic/Cape_Verde",
  },
  {
    code: "GMT",
    name: "Europe/Belfast",
  },
  {
    code: "GMT",
    name: "Africa/Abidjan",
  },
  {
    code: "GMT",
    name: "Europe/Dublin",
  },
  {
    code: "GMT",
    name: "Europe/Lisbon",
  },
  {
    code: "GMT",
    name: "Europe/London",
  },
  {
    code: "UTC",
    name: "UTC",
  },
  {
    code: "GMT+01:00",
    name: "Africa/Algiers",
  },
  {
    code: "GMT+01:00",
    name: "Africa/Windhoek",
  },
  {
    code: "GMT+01:00",
    name: "Atlantic/Azores",
  },
  {
    code: "GMT+01:00",
    name: "Atlantic/Stanley",
  },
  {
    code: "GMT+01:00",
    name: "Europe/Amsterdam",
  },
  {
    code: "GMT+01:00",
    name: "Europe/Belgrade",
  },
  {
    code: "GMT+01:00",
    name: "Europe/Brussels",
  },
  {
    code: "GMT+02:00",
    name: "Africa/Cairo",
  },
  {
    code: "GMT+02:00",
    name: "Africa/Blantyre",
  },
  {
    code: "GMT+02:00",
    name: "Asia/Beirut",
  },
  {
    code: "GMT+02:00",
    name: "Asia/Damascus",
  },
  {
    code: "GMT+02:00",
    name: "Asia/Gaza",
  },
  {
    code: "GMT+02:00",
    name: "Asia/Jerusalem",
  },
  {
    code: "GMT+03:00",
    name: "Africa/Addis_Ababa",
  },
  {
    code: "GMT+03:00",
    name: "Asia/Riyadh89",
  },
  {
    code: "GMT+03:00",
    name: "Europe/Minsk",
  },
  {
    code: "GMT+03:30",
    name: "Asia/Tehran",
  },
  {
    code: "GMT+04:00",
    name: "Asia/Dubai",
  },
  {
    code: "GMT+04:00",
    name: "Asia/Yerevan",
  },
  {
    code: "GMT+04:00",
    name: "Europe/Moscow",
  },
  {
    code: "GMT+04:30",
    name: "Asia/Kabul",
  },
  {
    code: "GMT+05:00",
    name: "Asia/Tashkent",
  },
  {
    code: "GMT+05:30",
    name: "Asia/Kolkata",
  },
  {
    code: "GMT+05:45",
    name: "Asia/Katmandu",
  },
  {
    code: "GMT+06:00",
    name: "Asia/Dhaka",
  },
  {
    code: "GMT+06:00",
    name: "Asia/Yekaterinburg",
  },
  {
    code: "GMT+06:30",
    name: "Asia/Rangoon",
  },
  {
    code: "GMT+07:00",
    name: "Asia/Bangkok",
  },
  {
    code: "GMT+07:00",
    name: "Asia/Novosibirsk",
  },
  {
    code: "GMT+08:00",
    name: "Etc/GMT+8",
  },
  {
    code: "GMT+08:00",
    name: "Asia/Hong_Kong",
  },
  {
    code: "GMT+08:00",
    name: "Asia/Krasnoyarsk",
  },
  {
    code: "GMT+08:00",
    name: "Australia/Perth",
  },
  {
    code: "GMT+08:45",
    name: "Australia/Eucla",
  },
  {
    code: "GMT+09:00",
    name: "Asia/Irkutsk",
  },
  {
    code: "GMT+09:00",
    name: "Asia/Seoul",
  },
  {
    code: "GMT+09:00",
    name: "Asia/Tokyo",
  },
  {
    code: "GMT+09:30",
    name: "Australia/Adelaide",
  },
  {
    code: "GMT+09:30",
    name: "Australia/Darwin",
  },
  {
    code: "GMT+09:30",
    name: "Pacific/Marquesas",
  },
  {
    code: "GMT+10:00",
    name: "Etc/GMT+10",
  },
  {
    code: "GMT+10:00",
    name: "Australia/Brisbane",
  },
  {
    code: "GMT+10:00",
    name: "Australia/Hobart",
  },
  {
    code: "GMT+10:00",
    name: "Asia/Yakutsk",
  },
  {
    code: "GMT+10:30",
    name: "Australia/Lord_Howe",
  },
  {
    code: "GMT+11:00",
    name: "Asia/Vladivostok",
  },
  {
    code: "GMT+11:30",
    name: "Pacific/Norfolk",
  },
  {
    code: "GMT+12:00",
    name: "Etc/GMT+12",
  },
  {
    code: "GMT+12:00",
    name: "Asia/Anadyr",
  },
  {
    code: "GMT+12:00",
    name: "Asia/Magadan",
  },
  {
    code: "GMT+12:00",
    name: "Pacific/Auckland",
  },
  {
    code: "GMT+12:45",
    name: "Pacific/Chatham",
  },
  {
    code: "GMT+13:00",
    name: "Pacific/Tongatapu",
  },
  {
    code: "GMT+14:00",
    name: "Pacific/Kiritimati",
  },
];

export const DateFormate = [
  { code: "DD-MM-YYYY", name: "DD-MM-YYYY" },
  { code: "YYYY-MM-DD HH:mm:ss", name: "YYYY-MM-DD HH:mm:ss" },
  { code: "YYYY-MM-DD", name: "YYYY-MM-DD" },
  { code: "YYYY / MM / DD", name: "YYYY / MM / DD" },
  { code: "DD / MM / YYYY", name: "DD / MM / YYYY" },
  { code: "DD / MM / YYYY , HH:mm", name: "DD / MM / YYYY , HH:mm" },
];

export const DefaultSortOrder = [
  { code: "DESC", name: "DESC" },
  { code: "ASC", name: "ASC" },
];

export const DashboardRedirect = [
  {
    name: "Today Order",
    redirect: REDIRECT_PATH.ORDERS,
  },
  {
    name: "This Month Order",
    redirect: REDIRECT_PATH.ORDERS,
  },
  {
    name: "Product Count",
    redirect: REDIRECT_PATH.PRODUCTS,
  },
  {
    name: "Category Count",
    redirect: REDIRECT_PATH.CATEGORY,
  },
  {
    name: "Inquiry Count",
    redirect: REDIRECT_PATH.INQUIRY_,
  },
  {
    name: "Brand Count",
    redirect: REDIRECT_PATH.BRAND_MASTER,
  },
  // {
  //   name: "Banner Count",
  //   redirect: REDIRECT_PATH.BANNER,
  // },
  // {
  //   name: "Slider Count",
  //   redirect: REDIRECT_PATH.SLIDER,
  // },
  {
    name: "Tag Count",
    redirect: REDIRECT_PATH.PRODUCTS_TAG,
  },
  // {
  //   name: "Detail Page Count",
  //   redirect: REDIRECT_PATH.DETAILS_PAGE,
  // },
  {
    name: "Store Count",
    redirect: REDIRECT_PATH.BRAND_MASTER,
  },
  {
    name: "User Count",
    redirect: REDIRECT_PATH.USER,
  },
];

export const PAYMENT_METHOD = {
  COD: 1,
  RAZOR_PAY: 4,
  APPLE_PAY: 3,
};

export const PAYMENT_METHOD_SHOW = {
  1: "Case On Delivery",
  4: "RazorPay",
  3: "Apple Pay",
};

export const PAYMENT_STATUS = {
  PAID: 1,
  PENDING: 2,
  FAILED: 3,
  DECLINED: 4,
};

export const PAYMENT_STATUS_SHOW = {
  1: "Paid",
  2: "Pending",
  3: "Failed",
  4: "Declined",
};

export const ORDER_STATUS = {
  INITIATED: 1,
  ALLOTTED: 2,
  ACCEPTED: 3,
  ARRIVED: 4,
  COLLECTED: 5,
  OUT_FOR_DELIVERY: 6,
  ARRIVED_CUSTOMER_DOORSTEP: 7,
  DELIVERED: 8,
  CANCELLED_BY_VENDOR: 302,
  UNDELIVERED: 9,
  ASSIGNED_ADMIN: 10,
  RETURNING_SELLER: 11,
  RETURNED_SELLER: 12,
  INITIATED_TRIP: 13,
  CLOSED_STORE: 14,
  DECLINE: 15,
  CANCELLED_BY_VENDOR: 16,
  CANCEL_REQUEST_BY_CUSTOMER: 17,
  CANCELLED_BY_CUSTOMER: 18,
};

export const ORDER_STATUS_SHOW = {
  1: "Initiated",
  2: "Allocated",
  3: "Accepted",
  4: "Arrived",
  5: "Collected",
  6: "Out For Delivery",
  7: "Arrived Customer Doorstep",
  8: "Delivered",
  302: "Cancelled By Vendor",
  9: "Undelivered",
  10: "Assigned Admin",
  11: "Returning Seller",
  12: "Returned Seller",
  13: "Initiated Trip",
  14: "Closed Store",
  15: "declined",
  16: "Cancelled By Admin",
  17: "Cancel Request By Customer",
  18: "Cancelled By Customer",
};

export const ORDER_STATUS_DROP = [
  { name: "Initiated", code: 1 },
  { name: "Allocated", code: 2 },
  { name: "Accepted", code: 3 },
  { name: "Arrived", code: 4 },
  { name: "Collected", code: 5 },
  { name: "Out of delivery", code: 6 },
  { name: "Arrived customer doorstep", code: 7 },
  { name: "Delivered", code: 8 },
  { name: "Cancelled by vender", code: 302 },
  { name: "Undelivered", code: 9 },
  { name: "Admin assigned", code: 10 },
  { name: "Returning seller", code: 11 },
  { name: "Returned seller", code: 12 },
  { name: "Initiated trip", code: 13 },
  { name: "Closed store", code: 14 },
  { name: "Dicline", code: 15 },
  { name: "Cancelled By Vendor", code: 16 },
  { name: "Cancel Request By Customer", code: 17 },
  { name: "Cancelled By Customer", code: 18 },
];

export const OREDR_STATUS1 = [
  {
    Value: 1,
    lable: "Initiated",
  },
  // { Value: 2, lable: "Allocated" },
  { Value: 3, lable: "Accepted" },
  // { Value: 4, lable: "Arrived" },
  // { Value: 5, lable: "Collected" },
  { Value: 6, lable: "Out of delivery" },
  { Value: 7, lable: "Arrived customer doorstep" },
  { Value: 8, lable: "Delivered" },
  { Value: 9, lable: "Undelivered" },
  { Value: 10, lable: "Assigned Admin" },
  // { Value: 11, lable: "Returning seller" },
  // { Value: 12, lable: "Returned seller" },
  // { Value: 13, lable: "Initiated trip" },
  // { Value: 14, lable: "Closed store" },
  { Value: 15, lable: "Declined" },
  { Value: 16, lable: "Cancelled By Admin" },
  { Value: 17, lable: "Cancel Request By Customer" },
  { Value: 18, lable: "Cancelled By Customer" },
];

export const SHIP_METHOD = {
  STORE_PICKUP: 1,
  DELIVERY: 2,
};

export const SHIP_METHOD_SHOW = {
  1: "Store Pickup",
  2: "Delivery",
};

export const SHIP_STATUS = {
  ON_THE_WAY_TO_PICKUP: 1,
  ORDER_PICKED_UP: 2,
  OUT_FOR_DELIVERY: 3,
  VEHICLE_BREAKDOWN: 4,
  ARRIVED_AT_DESTINATION: 5,
  ATTEMPTED_DELIVERY: 6,
  DELIVERED: 7,
  SCHEDULED_FOR_DELIVERY: 8,
};

export const SHIP_STATUS_SHOW = {
  1: "On the way to pickup",
  2: "Order picked up",
  3: "Out for delivery",
  4: "Vehicle breakdown",
  5: "Arrived at destination",
  6: "Delivery attempted",
  7: "Delivered",
  8: "Scheduled for delivery",
};

// Promo Code Constatnts
// Deal Of The Day Constatnts
export const DISCOUNT_TYPE = [
  { code: 1, name: "Percentage" },
  { code: 2, name: "Fixed Amount" },
];

export const DISCOUNT_APPLY_TYPE = [
  { code: 1, name: "All" },
  { code: 2, name: "Category" },
  { code: 3, name: "Product" },
];

export const ATTRIBUTE_TYPE_DROP = [
  { code: 1, name: "Box" },
  { code: 2, name: "Drop Down" },
  { code: 3, name: "Image" },
];

export const TEXT_TYPE = [
  { code: "exclusive", name: "Exclusive" },
  { code: "inclusive", name: "Inclusive" },
];

export const PROMO_CODE_APPLY_TYPE = {
  ALL: 1,
  CATEGORY: 2,
  PRODUCT: 3,
};
