import React, { useContext, useState } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  SLACE,
  ORDER_SETTING_,
  ORDER_SETTING_LIST,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import {
  TImeZoneArr,
  DateFormate,
  optionsDialCode,
  Currency,
  FileType,
} from "../../../../constants";
import { createOrderSettingSchema } from "../../../../utils/validationSchema";
import { Put_Update_Order_Setting } from "../../../../Apis/orderSettingApi/OrderSettingApi";
import CropImagePopup from "../../../CropImagePopup";
import { Upload_Image } from "../../../../Apis/userApi/UserApi";
import OrderSettingContext from "../../../../context/OrderSettingContext/OrderSettingContext";
import { get_Order_Setting } from "../../../../Apis/orderSettingApi/OrderSettingApi";
import OrderSettingDataTabelContainer from "./OrderSettingDataTabelContainer";

const OrderSetting = () => {
  const [dialogModal, setdialogsModal] = useState(false);
  const [settingId, setSettingId] = useState(0);
  const { mutate: uploadImage } = useMutation((file) =>
    Upload_Image(file, setdialogsModal, setFormData, FileType.FEATURES)
  );
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,
    setFormData,
    isEdit,
    setdialogs,
    formData,
    setErrors,

    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
    //order setting edit condition state and data object state
    setFilterCurrency,
    setFilterDefaultTime,
    setFilterDefaultDate,
    setFilterDefaultMobile,
    setFilterDefaultWhatsapp,

    setHeader,
    setKey,
  } = useContext(OrderSettingContext);
  const history = useNavigate();

  const { mutate: updateOrderSetting } = useMutation((formData) => {
    Put_Update_Order_Setting(
      formData,
      settingId,
      refetch,
      setdialogs,
      emptyData
    );
  });
  const emptyData = () => {
    setFilterCurrency("");
    setFilterDefaultTime("");
    setFilterDefaultDate("");
    setFilterDefaultMobile("");
    setFilterDefaultWhatsapp("");
    setFormData(() => {
      return {
        taxLabel: "",
        numberOfImagesPerProduct: "",
        defaultTimeZone: null,
        defaultDateFormat: null,
        companyName: "",
        companyLogo: "",
        image: "",
        address: "",
        email: "",
        mobile: "",
        whatsapp: "",
        mobileDialCode: null,
        whatsappDialCode: null,
        city: "",
        state: "",
        country: "",
        pincode: "",
        taxID: "",
        maxOrderQty: "",
        minOrderAmount: "",
        deliveryCharge: "",
        currency: null,
        adminPercentage: "",
        skipLogin: false,
        stockShow: false,
        defaultSortListing: "DESC",
        androidKey: "",
        iosKey: "",
        invoiceTerms: "",
        orderOnWhatsapp: "",
        websiteTitle: "",
        seoTitle: "",
        seoDesc: "",
        seoKeyword: "",
        googleTag: "",
        facebookTag: "",
        schema: "",
        facebook: "",
        x: "",
        youtube: "",
        playStoreLink: "",
      };
    });
  };

  /**
   * get order setting List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = JSON.stringify({ name: filter });
  }

  let { data, isLoading, refetch, error } = useQuery(
    ["getOrderSetting", paramsObj],

    async () => await get_Order_Setting(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          let data = response.payload.data[0];
          let [filteredDate] = DateFormate.filter(
            (elem) => elem.code === data?.defaultDateFormat
          );
          let [filteredTime] = TImeZoneArr.filter(
            (elem) => elem.code === data?.defaultTimeZone
          );
          let [filteredMobile] = optionsDialCode.filter(
            (elem) => elem.code === data?.mobileDialCode
          );
          let [filteredWhatsapp] = optionsDialCode.filter(
            (elem) => elem.code === data?.whatsappDialCode
          );

          let [currency] = Currency.filter(
            (elem) => elem.code === data?.currency
          );

          setFormData({
            taxLabel: data ? data?.taxLabel : null,
            numberOfImagesPerProduct: data
              ? data?.numberOfImagesPerProduct
              : null,
            defaultTimeZone: filteredTime,
            defaultDateFormat: filteredDate,
            companyName: data ? data?.companyName : null,
            companyLogo: data ? data?.companyLogo : null,
            image: data ? data?.companyLogo : null,
            address: data ? data?.address : null,
            email: data ? data?.email : null,
            mobile: data ? data?.mobile : null,
            whatsapp: data ? data?.whatsapp : null,
            mobileDialcode: filteredMobile,
            whatsappDialcode: filteredWhatsapp,
            city: data ? data?.city : null,
            state: data ? data?.state : null,
            country: data ? data?.country : null,
            pincode: data ? data?.pincode : null,
            taxID: data ? data?.taxID : null,

            maxOrderQty: data ? data?.maxOrderQty : null,
            minOrderAmount: data ? data?.minOrderAmount : null,
            deliveryCharge: data ? data?.deliveryCharge : null,
            currency: data ? currency : null,
            adminPercentage: data ? data?.adminPercentage : null,
            skipLogin: data ? data?.skipLogin : false,
            stockShow: data ? data?.stockShow : false,
            defaultSortListing: {
              code: data?.defaultSortListing,
              name: data?.defaultSortListing,
            },
            androidKey: data ? data?.androidKey : null,
            iosKey: data ? data?.iosKey : null,
            invoiceTerms: data ? data?.invoiceTerms : null,
            orderOnWhatsapp: data ? data?.orderOnWhatsapp : null,
            websiteTitle: data ? data?.websiteTitle : null,
            seoTitle: data ? data?.seoTitle : null,
            seoDesc: data ? data?.seoDesc : null,
            seoKeyword: data ? data?.seoKeyword : null,
            googleTag: data ? data?.googleTag : null,
            facebookTag: data ? data?.facebookTag : null,
            schema: data ? data?.schema : null,
            facebook: data ? data?.facebook : null,
            x: data ? data?.x : null,
            youtube: data ? data?.youtube : null,
            playStoreLink: data ? data?.playStoreLink : null,
            codOnOff: data ? data?.codOnOff : null,
            isOnlinePayment: data ? data?.isOnlinePayment : null,
            codCharge: data ? data?.codCharge : null,
          });
          setSettingId(response.payload?.data[0]?.globalSettingId);

          setRecord(response.pager.totalRecords);
        }
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }
  const handleOrderSettingCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createOrderSettingSchema();
      await schema.validate(formData, { abortEarly: false });
      if (settingId !== -1 && isEdit === false) {
        let newData = {
          ...formData,
          defaultTimeZone: formData?.defaultTimeZone
            ? formData?.defaultTimeZone?.code
            : null,
          defaultDateFormat: formData?.defaultDateFormat
            ? formData?.defaultDateFormat?.code
            : null,
          mobileDialCode: formData?.mobileDialcode
            ? formData?.mobileDialcode?.code
            : null,
          currency: formData?.currency ? formData?.currency?.code : null,
          defaultSortListing: formData?.defaultSortListing
            ? formData?.defaultSortListing?.code
            : null,
          whatsappDialCode: formData?.whatsappDialcode
            ? formData?.whatsappDialcode?.code
            : null,
          companyLogo: formData?.image
            ? formData?.image.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : null,
          image: formData?.image
            ? formData?.image.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : null,
        };
        updateOrderSetting(newData);
      } else {
        let newData = {
          ...formData,
          defaultTimeZone: formData?.defaultTimeZone
            ? formData?.defaultTimeZone?.code
            : null,
          defaultDateFormat: formData?.defaultDateFormat
            ? formData?.defaultDateFormat?.code
            : null,
          mobileDialCode: formData?.mobileDialcode
            ? formData?.mobileDialcode?.code
            : null,
          currency: formData?.currency ? formData?.currency?.code : null,
          defaultSortListing: formData?.defaultSortListing
            ? formData?.defaultSortListing?.code
            : null,
          whatsappDialCode: formData?.whatsappDialcode
            ? formData?.whatsappDialcode?.code
            : null,
          companyLogo: formData?.image
            ? formData?.image.split(process.env.REACT_APP_FILE_FOLDER).pop()
            : null,
        };
        updateOrderSetting(newData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  /**
   * Open Popup and Close Popup User
   */
  const handleOpenandClosePopup = (condition, header, key) => {
    setHeader(header);
    setKey(key);

    if (condition) {
      setdialogs(condition);
      // emptyData();
      setErrors({});
    } else {
      setdialogs(condition);
      // emptyData();
      setErrors({});
    }
  };

  /**
   * Open Popup and Close Popup order setting
   */
  return (
    <>
      <Breadcrumb
        name={ORDER_SETTING_}
        slace={SLACE}
        name1={ORDER_SETTING_LIST}
      />

      <OrderSettingDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
        updateOrderSetting={updateOrderSetting}
      />

      <CropImagePopup
        dialogModal={dialogModal}
        setdialogsModal={setdialogsModal}
        image={formData?.image}
        aspect={aspect}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        setFormData={setFormData}
        uploadImage={uploadImage}
      />
    </>
  );
};

export default OrderSetting;
