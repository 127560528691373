import React, { useContext } from "react";
import CssContext from "../context/css_context/CssContext/CssContext";

const Footer = (props) => {
  const { maindivSidebar, windowSize } = props;
  const { darkMode } = useContext(CssContext);
  return (
    <footer
      className={`
       ${
         windowSize.innerWidth <= 991.98
           ? "footer-normal"
           : maindivSidebar === true
           ? "footer-normal"
           : maindivSidebar === false
           ? "footer-static"
           : "footer-normal"
       }
       ${darkMode ? "dark-footer" : ""}
          `}
    >
      <div>
        Copyright © 2025. All Rights Reserved
        <a
          href="https://www.ajinfosoft.com/"
          className={darkMode ? "dark-link" : ""}
          style={{ paddingLeft: "5px", color: "rgb(99, 102, 241) " }}
        >
          Aj-infosoft
        </a>
      </div>
    </footer>
  );
};

export default Footer;
